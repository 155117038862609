export default [
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'HomeIcon',
    resource: 'DASHBOARD',
    action: 'read',
  },
  {
    title: 'Makineler',
    route: 'AppMachines',
    icon: 'BoxIcon',
    resource: 'APP_MACHINES_LIST',
    action: 'read',
  },
  {
    title: 'Servis Kayıtları',
    route: 'AppServices',
    icon: 'FileTextIcon',
    resource: 'APP_SERVICES_LIST',
    action: 'read',
  },
  {
    title: 'Servis Taleplerim',
    route: 'AppServiceRequests',
    icon: 'EditIcon',
    resource: 'APP_SERVICE_REQUESTS_LIST',
    action: 'read',
  },
]
